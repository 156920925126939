import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import ClientCategories from "../components/Clients/ClientCategories"
import ClientList from "../components/Clients/ClientList"
import Paginator from "../components/Paginator"
import SEO from "../components/seo"
import heroImage from "../images/clents_bg.jpg"
import { withPreview } from "gatsby-source-prismic"

const ClientsList = ({ data, ...props }) => {
  const posts = data.allPrismicClients.edges
  const postNodes = posts.sort(
    (a, b) =>
      new Date(b.node.data.publish_date).getTime() - new Date(a.node.data.publish_date).getTime(),
  )

  return (
    <Layout>
      <SEO title="Brands rely on Vindicia subscription billing &amp; recurring revenue solutions." />
      <Header image={heroImage} title={"Over 100 clients all around the globe rely on Vindicia"} />
      <ClientCategories categories={data.allPrismicClientCategories.edges.map(c => c.node)} />
      <section className="uk-section">
        <div className="uk-container uk-container-medium">
          <ClientList posts={postNodes} />
        </div>
      </section>
      <Paginator context={props.pageContext} path={"/list-of-clients"} />
    </Layout>
  )
}

export default withPreview(ClientsList)

export const clientListQuery = graphql`
  query clientListQuery($skip: Int!, $limit: Int!) {
    allPrismicClients(
      sort: { fields: data___company_name, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            company_name
            company_url {
              url
            }
            description {
              html
              text
            }
            logo {
              fixed(width: 160) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            hero_image {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            categories {
              category {
                uid
              }
            }
          }
        }
      }
    }
    allPrismicClientCategories {
      edges {
        node {
          data {
            label
          }
          uid
        }
      }
    }
  }
`
