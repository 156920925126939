import React from "react"
import { Image } from "../VindiciaComponents"

const ClientList = ({ posts }) => {
  const filterCategories = val => {
    const v = val.map(c => c.category.uid)
    return v.join(" ")
  }

  return (
    <div
      className="uk-grid-column-medium uk-child-width-1-3@s uk-text-center"
      data-uk-grid
      data-uk-height-match="target: > div > .uk-card > .uk-card-body"
    >
      {posts.map((post, i) => (
        <div key={post.node.uid}>
          <div
            className="uk-card uk-card-default uk-card-small"
            data-tags={filterCategories(post.node.data.categories)}
          >
            <div className="uk-card-media-top uk-position-relative">
              {post.node.data.hero_image.fixed && (
                <Image
                  src={post.node.data.hero_image.fixed.src}
                  srcSet={post.node.data.hero_image.fixed.srcSet}
                  width={post.node.data.hero_image.fixed.width}
                  height={post.node.data.hero_image.fixed.height}
                  alt="Vindicia client"
                />
              )}
              <div className="uk-overlay uk-overlay-primary-light uk-position-cover uk-text-left">
                <div className="uk-position-bottom-left uk-position-medium">
                  {post.node.data.logo.fixed && (
                    <Image
                      src={post.node.data.logo.fixed.src}
                      srcSet={post.node.data.logo.fixed.srcSet}
                      width={post.node.data.logo.fixed.width}
                      height={post.node.data.logo.fixed.height}
                      alt="Logo"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="uk-card-body uk-text-left">
              <h3 className="uk-margin-remove-top">{post.node.data.company_name}</h3>
            </div>
            <div className="uk-card-footer uk-text-left">
              <a
                className="uk-button uk-button-primary uk-button-large uk-light uk-border-pill"
                href={post.node.data.company_url.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit site
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ClientList
