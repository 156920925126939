import React from "react"
import { Link } from "gatsby"

const ClientCategories = props => {
  const postNodes = props.categories.sort((a, b) => (a.uid > b.uid ? 1 : -1))

  return (
    <div className="uk-container uk-margin-medium-top">
      <button className="uk-position-relative uk-button-large" type="button">
        <span className="uk-position-right uk-position-small" data-uk-icon="chevron-down" />
        <span className="uk-margin-right">{props.category ? props.category : "All Category"}</span>
      </button>
      <div data-uk-dropdown="mode: click; pos: bottom-center; offset: -40">
        <ul className="uk-nav">
          <li>
            <Link to={`/list-of-clients`}>All Category</Link>
          </li>
          {postNodes.map(c => (
            <li key={c.uid}>
              <Link to={`/list-of-clients/${c.uid}`}>{c.data.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ClientCategories
